<template>
  <TTView>
    <VRow>
      <VCol>
        <h1> Редактирование курса </h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VForm
          ref="form"
          v-model="isValid"
          lazy-validation
        >
          <VRow>
            <VCol>
              <TTTextField
                v-model.trim="course.name"
                label="Название курса"
                :rules="[rules.required]"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <label>Описание</label>
              <TTTextarea
                v-model.trim="course.description"
                :rules="[rules.required]"
                class="mt-2"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <VAutocomplete
                v-model="selectedTopics"
                :items="topics"
                item-text="name"
                item-value="id"
                label="Выберите топики"
                chips
                deletable-chips
                multiple
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <CourseLabels
                :added-labels="course.labels"
                @click:add="addLabel"
                @click:remove="removeLabel"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <TTBtn
                :loading="courseLoading"
                :disabled="!isValid || courseLoading"
                @click="submit"
              >
                Обновить курс
              </TTBtn>
            </VCol>
          </VRow>
        </VForm>
      </VCol>
    </VRow>
  </TTView>
</template>
<script>
import CourseLabels from '../../components/learning/CourseLabels.vue';

const DEFAULT_COURSE = {
  name: '',
  description: '',
  topicIds: [],
  status: 'STATUS_INVALID', // По-умолчанию
  labels: [],
};

export default {
  name: 'EditCourse',
  components: {
    CourseLabels,
  },
  data() {
    return {
      course: { ...DEFAULT_COURSE },
      topics: [],
      rules: {
        required: (v) => !!v || 'Обязательное поле',
      },
      selectedTopics: [],
      courseLoading: false,
      isValid: false,
    };
  },
  watch: {
    topics: {
      handler(value) {
        this.course.topicIds.forEach((preSelectedTopicId) => {
          const foundTopic = value.find((topic) => topic.id === preSelectedTopicId);
          if (foundTopic) {
            this.selectedTopics.push(foundTopic.id);
          }
        });
      },
      immediate: true,
    },
    selectedTopics: {
      handler(value) {
        this.course.topicIds = value;
      },
    },
  },
  async created() {
    await this.fetchCourse();
    await this.fetchTopicsItems();
  },
  methods: {
    submit() {
      this.$refs.form.validate();

      if (this.isValid) {
        this.updateCourse();
      }
    },
    async updateCourse() {
      try {
        this.courseLoading = true;
        const { id } = this.course;
        delete this.course.id;
        await this.$di.api.TtCourse.updateCourse({
          id,
          course: {
            ...this.course,
          },
        });
        this.$di.notify.snackSuccess('Курс обновлен');
      } catch (error) {
        this.$di.notify.errorHandler(error);
      } finally {
        this.courseLoading = false;
      }
    },
    async fetchCourse() {
      try {
        const { course } = await this.$di.api.TtCourse.getCourse({ id: this.$route.params.courseId });
        this.course = {
          ...this.course,
          ...course,
        };
      } catch (e) {
        this.$di.notify.errorHandler(e);
      }
    },
    async fetchTopicsItems() {
      try {
        const { topics } = await this.$di.api.TtCourse.searchTopics();
        this.topics = topics.map((topic) => ({
          key: topic.key,
          id: topic.id,
          name: `NAME: ${topic.name} KEY: ${topic.key} ID: ${topic.id}`,
        }));
      } catch (e) {
        this.$di.notify.errorHandler(e);
      }
    },
    addLabel(label) {
      this.course.labels.push(label);
    },
    removeLabel(label) {
      this.course.labels = this.course.labels.filter(({ key, value }) => key !== label.key && value !== label.value);
    },
  },
};
</script>
