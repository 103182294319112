<template>
  <div>
    <VRow>
      <VCol>
        <VAlert
          colored-border
          border="left"
          type="warning"
          elevation="1"
          class="mt-5"
        >
          Не забудьте метки:

          <ul>
            <li><strong>skill</strong> = id навыка</li>
            <li><strong>level</strong> = уровень навыка</li>
          </ul>
        </VAlert>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <h2 class="title"> Добавить метку </h2>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VTextField
          v-model="labelKey"
          label="Имя"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VTextField
          v-model="labelValue"
          label="Значение"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VBtn
          depressed
          color="primary"
          @click="addLabel"
        >
          Добавить метку
        </VBtn>
      </VCol>
    </VRow>
    <VRow v-if="addedLabels.length">
      <VCol>
        <VDivider class="my-4"/>
        <span class="subheader">Добавленные метки</span>
        <VChipGroup>
          <VChip
            v-for="label in addedLabels"
            :key="`${label.key}_${label.value}`"
            close
            text-color="black"
            @click:close="removeLabel(label)"
          >
            <span>
              <span class="font-weight-bold">name:</span> {{ label.key }}&nbsp;
              <span class="font-weight-bold">value:</span> {{ label.value }}
            </span>
          </VChip>
        </VChipGroup>
      </VCol>
    </VRow>
  </div>
</template>
<script>
export default {
  name: 'CourseLabels',
  props: {
    addedLabels: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      labelKey: '',
      labelValue: '',
    };
  },
  methods: {
    addLabel() {
      const label = {
        key: this.labelKey,
        value: this.labelValue,
      };

      this.$emit('click:add', label);

      this.clearLabel();
    },
    removeLabel(label) {
      this.$emit('click:remove', label);
    },
    clearLabel() {
      this.labelKey = '';
      this.labelValue = '';
    },
  },
};
</script>
